import { useState, useEffect } from 'react'
import {
  Box,
  Input,
  VStack,
  Text,
  Button,
  Select,
  useToast,
  Container,
  Heading,
  InputGroup,
  InputRightElement,
  List,
  ListItem
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import bibleApi from '../services/bibleApi'

interface Bible {
  id: string
  name: string
}

interface SearchResult {
  reference: string
  text: string
}

const Search = () => {
  const [bibles, setBibles] = useState<Bible[]>([])
  const [selectedBible, setSelectedBible] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [results, setResults] = useState<SearchResult[]>([])
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    const loadBibles = async () => {
      try {
        const data = await bibleApi.getBibles()
        setBibles(data)
      } catch (error) {
        toast({
          title: 'Error loading Bibles',
          status: 'error',
          duration: 3000
        })
      }
    }
    loadBibles()
  }, [])

  const handleSearch = async () => {
    if (!selectedBible || !searchQuery.trim()) {
      toast({
        title: 'Please select a Bible version and enter a search query',
        status: 'warning',
        duration: 3000
      })
      return
    }

    setLoading(true)
    try {
      const data = await bibleApi.searchBible(selectedBible, searchQuery)
      setResults(data.verses || [])
    } catch (error) {
      toast({
        title: 'Error performing search',
        status: 'error',
        duration: 3000
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxW="container.lg">
      <VStack spacing={8} align="stretch">
        <Box>
          <Heading size="lg" mb={6}>
            Search the Bible
          </Heading>
          <VStack spacing={4}>
            <Select
              placeholder="Select Bible version"
              value={selectedBible}
              onChange={(e) => setSelectedBible(e.target.value)}
            >
              {bibles.map((bible) => (
                <option key={bible.id} value={bible.id}>
                  {bible.name}
                </option>
              ))}
            </Select>

            <InputGroup size="lg">
              <Input
                placeholder="Enter your search query..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleSearch}
                  isLoading={loading}
                >
                  <SearchIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
          </VStack>
        </Box>

        {results.length > 0 && (
          <List spacing={4}>
            {results.map((result, index) => (
              <ListItem
                key={index}
                p={4}
                bg="white"
                borderRadius="md"
                shadow="sm"
                transition="all 0.2s"
                _hover={{ shadow: 'md' }}
              >
                <Text fontWeight="bold" color="blue.600" mb={2}>
                  {result.reference}
                </Text>
                <Text dangerouslySetInnerHTML={{ __html: result.text }} />
              </ListItem>
            ))}
          </List>
        )}
      </VStack>
    </Container>
  )
}

export default Search
