import axios from 'axios'

const API_KEY = import.meta.env.VITE_BIBLE_API_KEY
const BASE_URL = 'https://api.scripture.api.bible/v1'

const bibleApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'api-key': API_KEY
  }
})

export const getBibles = async () => {
  const response = await bibleApi.get('/bibles')
  return response.data.data
}

export const getBooks = async (bibleId: string) => {
  const response = await bibleApi.get(`/bibles/${bibleId}/books`)
  return response.data.data
}

export const getChapter = async (bibleId: string, chapterId: string) => {
  const response = await bibleApi.get(`/bibles/${bibleId}/chapters/${chapterId}`)
  return response.data.data
}

export const searchBible = async (bibleId: string, query: string) => {
  const response = await bibleApi.get(`/bibles/${bibleId}/search`, {
    params: {
      query,
      limit: 20
    }
  })
  return response.data.data
}

export default {
  getBibles,
  getBooks,
  getChapter,
  searchBible
}
