import { Box, Flex, Link, Heading } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <Box bg="white" px={4} shadow="sm">
      <Flex h={16} alignItems="center" justifyContent="space-between" maxW="container.xl" mx="auto">
        <Heading as={RouterLink} to="/" size="lg" color="blue.600">
          Bible App
        </Heading>
        
        <Flex gap={8}>
          <Link as={RouterLink} to="/" color="gray.600" fontWeight="medium">
            Home
          </Link>
          <Link as={RouterLink} to="/read" color="gray.600" fontWeight="medium">
            Read
          </Link>
          <Link as={RouterLink} to="/search" color="gray.600" fontWeight="medium">
            Search
          </Link>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Navbar
