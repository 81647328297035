import { useState, useEffect } from 'react'
import {
  Box,
  Select,
  Text,
  VStack,
  HStack,
  Button,
  useToast,
  Spinner,
  Container
} from '@chakra-ui/react'
import bibleApi from '../services/bibleApi'

interface Bible {
  id: string
  name: string
}

interface Book {
  id: string
  name: string
  chapters: { id: string; number: string }[]
}

const BibleReader = () => {
  const [bibles, setBibles] = useState<Bible[]>([])
  const [selectedBible, setSelectedBible] = useState('')
  const [books, setBooks] = useState<Book[]>([])
  const [selectedBook, setSelectedBook] = useState('')
  const [selectedChapter, setSelectedChapter] = useState('')
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    const loadBibles = async () => {
      try {
        const data = await bibleApi.getBibles()
        setBibles(data)
      } catch (error) {
        toast({
          title: 'Error loading Bibles',
          status: 'error',
          duration: 3000
        })
      }
    }
    loadBibles()
  }, [])

  useEffect(() => {
    const loadBooks = async () => {
      if (selectedBible) {
        try {
          const data = await bibleApi.getBooks(selectedBible)
          setBooks(data)
        } catch (error) {
          toast({
            title: 'Error loading books',
            status: 'error',
            duration: 3000
          })
        }
      }
    }
    loadBooks()
  }, [selectedBible])

  const loadChapter = async () => {
    if (selectedBible && selectedChapter) {
      setLoading(true)
      try {
        const data = await bibleApi.getChapter(selectedBible, selectedChapter)
        setContent(data.content)
      } catch (error) {
        toast({
          title: 'Error loading chapter',
          status: 'error',
          duration: 3000
        })
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Container maxW="container.lg">
      <VStack spacing={6} align="stretch">
        <HStack spacing={4}>
          <Select
            placeholder="Select Bible version"
            value={selectedBible}
            onChange={(e) => setSelectedBible(e.target.value)}
          >
            {bibles.map((bible) => (
              <option key={bible.id} value={bible.id}>
                {bible.name}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Select Book"
            value={selectedBook}
            onChange={(e) => setSelectedBook(e.target.value)}
            isDisabled={!selectedBible}
          >
            {books.map((book) => (
              <option key={book.id} value={book.id}>
                {book.name}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Select Chapter"
            value={selectedChapter}
            onChange={(e) => {
              setSelectedChapter(e.target.value)
              loadChapter()
            }}
            isDisabled={!selectedBook}
          >
            {books
              .find((b) => b.id === selectedBook)
              ?.chapters?.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.number}
                </option>
              ))}
          </Select>
        </HStack>

        <Box
          bg="white"
          p={8}
          borderRadius="lg"
          shadow="sm"
          minH="60vh"
          position="relative"
        >
          {loading ? (
            <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <Spinner size="xl" color="blue.500" />
            </Box>
          ) : (
            <Text
              fontSize="lg"
              lineHeight="tall"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </Box>
      </VStack>
    </Container>
  )
}

export default BibleReader
