import { Box, Heading, Text, Button, VStack, useColorModeValue } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

const Home = () => {
  const bgColor = useColorModeValue('white', 'gray.800')

  return (
    <VStack spacing={8} align="center" justify="center" minH="calc(100vh - 200px)">
      <Box
        p={10}
        bg={bgColor}
        borderRadius="xl"
        shadow="xl"
        maxW="800px"
        w="100%"
        textAlign="center"
      >
        <Heading size="2xl" mb={6} color="blue.600">
          Welcome to the Bible App
        </Heading>
        <Text fontSize="xl" mb={8} color="gray.600">
          Explore the scripture with our modern, easy-to-use interface. Read, search, and study the Bible
          with a beautiful and intuitive experience.
        </Text>
        <VStack spacing={4}>
          <Button
            as={RouterLink}
            to="/read"
            size="lg"
            colorScheme="blue"
            width={{ base: 'full', md: 'auto' }}
          >
            Start Reading
          </Button>
          <Button
            as={RouterLink}
            to="/search"
            size="lg"
            variant="outline"
            colorScheme="blue"
            width={{ base: 'full', md: 'auto' }}
          >
            Search Scripture
          </Button>
        </VStack>
      </Box>
    </VStack>
  )
}

export default Home
